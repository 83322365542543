import { Button } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import CancelPopup from '../../../../clients/client-history/cancel-popup/CancelPopup';
import { cancelMeeting } from '../../../../../helper/api.helper';
import toast from 'react-hot-toast';
import Loader from '../../../../../shared/components/loader/Loader';

const BookingItem = ({ booking }) => {
  console.log(booking);
  const [cancelled, setCancelled] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSave = () => {
    console.log(text);
    setCancelled(true);
    setLoading(true);
    cancelMeeting({ meetingId: booking.id, reason: text })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          toast.success('Meeting cancelled successfully');
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
    // handleClose();
  };

  useEffect(() => {
    if (booking.type === 2 && booking.status === 3) {
      setCancelled(true);
      if (booking.cancellationReason) {
        setText(booking.cancellationReason);
      }
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <div className="booking-details-container flex-container justify-between">
        {isLoading ? <Loader /> : null}

        <div className="details-container flex-container justify-between">
          <div className="meeting-details">
            <p>
              {format(new Date(booking.meetingStart), 'hh:mm a')} -{' '}
              {format(new Date(booking.meetingEnd), 'hh:mm a')}
            </p>
            <p>{booking.serviceName}</p>
          </div>
          <div className="client-details">
            <p>{booking.client_name}</p>
            <p> {booking.client_email}</p>
            {/* <p>{booking.client_contact}</p> */}
          </div>
          <div className="location-details">
            <p> {booking.locationName}</p>
            {/* <p>{booking.fees}</p> */}
          </div>
        </div>
        <div className="btn-container flex-container direction-column">
          {booking.meetingLink && (
            <Button
              href={booking.meetingLink}
              target="_blank"
              variant="contained"
              color="primary"
              size="small">
              Join meeting
            </Button>
          )}
          {/* <Button
            color="primary"
            size="small"
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left'
            }}>
            Add notes
          </Button>
          <Button
            color="primary"
            size="small"
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left'
            }}>
            Reschedule meeting
          </Button> */}
          <Button
            onClick={handleClickOpen}
            // color="primary"
            size="small"
            style={{
              justifyContent: 'flex-start',
              textAlign: 'left',
              color: '#ff0000'
            }}>
            {cancelled ? 'Cancellation Note' : 'Cancel Meeting'}
          </Button>
        </div>
      </div>
      <CancelPopup
        setText={setText}
        text={text}
        open={open}
        handleClose={handleClose}
        handleSave={handleSave}
        handleOpen={handleOpen}
        cancelled={cancelled}
        bookingItem={booking}
      />
      <style jsx>{`
        .booking-details-container {
          border-top: 1px solid var(--border-gray);
          padding: 15px 10px 15px 50px;
        }
        .details-container {
          width: 70%;
          flex-wrap: wrap;
        }

        .client-details p,
        .meeting-details p,
        .location-details p {
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-all;
        }
        .meeting-details {
          width: 32%;
        }
        .client-details {
          width: 32%;
        }
        .location-details {
          width: 32%;
        }
        .btn-container {
          width: 25%;
          max-width: 200px;
        }
        @media only screen and (max-width: 950px) {
          .booking-details-container {
            padding: 15px 10px 15px 10px;
          }
          .details-container {
            flex-direction: column;
            width: 60%;
          }
          .meeting-details {
            width: 95%;
          }
          .client-details {
            width: 95%;
            margin-top: 20px;
          }
          .location-details {
            width: 95%;
            margin-top: 20px;
          }
          .btn-container {
            width: 40%;
            max-width: 200px;
          }
        }
      `}</style>
    </>
  );
};

export default BookingItem;
