import { query, orderBy, collection, where, limit, getDocs, startAfter } from 'firebase/firestore';

import { database } from '../../../helper/firebase.helper';

const PAGE_SIZE = 20;

export const fetchClients = async (lastVisible, orgId, sortOption) => {
  const clientsRef = collection(database, 'clients');
  // let q = query(
  //        clientsRef,
  //        where("organisation", "==", orgId),
  //        orderBy("name", "desc"),
  //        limit(PAGE_SIZE)
  //      );

  let q;

  switch (sortOption) {
    case 1:
      q = query(
        clientsRef,
        where('organisations', 'array-contains-any', orgId),
        orderBy('register_time', 'desc'),
        limit(PAGE_SIZE)
      );
      break;

    case 2:
      q = query(
        clientsRef,
        where('organisations', 'array-contains-any', orgId),
        orderBy('register_time', 'asc'),
        limit(PAGE_SIZE)
      );
      break;

    case 3:
      q = query(
        clientsRef,
        where('organisations', 'array-contains-any', orgId),
        orderBy('name', 'asc'),
        limit(PAGE_SIZE)
      );
      break;

    case 4:
      q = query(
        clientsRef,
        where('organisations', 'array-contains-any', orgId),
        orderBy('name', 'desc'),
        limit(PAGE_SIZE)
      );
      break;

    default:
      q = query(
        clientsRef,
        where('organisations', 'array-contains-any', orgId),
        orderBy('register_time', 'desc'),
        limit(PAGE_SIZE)
      );
      break;
  }

  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }

  const querySnapshot = await getDocs(q);

  let dataArray = [];
  querySnapshot.forEach((doc) => {
    dataArray.push({ id: doc.id, ...doc.data() });
  });

  if (dataArray.length < PAGE_SIZE) {
    // If the fetched data is less than the page size, it means there are no more pages
    return {
      data: dataArray,
      hasMore: false,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
  }

  return {
    data: dataArray,
    hasMore: true,
    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
  };
};

export const fetchClientHistory = async (orgId, clientId) => {
  const historyRef = collection(database, 'activities');
  let q = query(
    historyRef,
    where('organisation', 'in', orgId),
    where('client', '==', clientId),
    orderBy('update_time', 'desc'),
    limit(1)
  );

  const querySnapshot = await getDocs(q);

  let docData = null;
  querySnapshot.forEach((doc) => {
    docData = { id: doc.id, ...doc.data() };
  });

  return {
    data: docData
  };
};

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return '#4dd74d';
    case 0:
      return '#f4db05';
    case 2:
      return 'red';
    case 3:
      return 'red';
    default:
      return '#0884ff';
  }
};

export const getDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);

  const formattedDateYear = `${formattedDate} `;

  return formattedDateYear;
};
