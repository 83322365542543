import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Fab,
  IconButton
} from '@mui/material';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { useStoreState } from 'easy-peasy';
import { database } from '../../../helper/firebase.helper';
import { getAllServices } from '../../../helper/api.helper';
import Loader from '../../../shared/components/loader/Loader';

const ServiceList = () => {
  const practitioner = useStoreState((state) => state.user);
  const [services, setServices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const [serviceChanged, setServiceChanged] = useState(false);

  const getServices = () => {
    setLoading(true);
    getAllServices()
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 'success') {
          setServices(res.data.services);
          setLocations(res.data.locations);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  const handleServiceChange = (e, serviceId) => {
    const { name, value } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId ? { ...service, [name]: value } : service
      )
    );
    setServiceChanged(true);
  };

  const handleLocationChange = (e, serviceId, location) => {
    const { checked } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) => {
        if (service.id === serviceId) {
          const updatedLocations = checked
            ? [...service.locations, { id: location.id, name: location.name, price: 0 }]
            : service.locations.filter((loc) => loc.id !== location.id);
          return { ...service, locations: updatedLocations };
        }
        return service;
      })
    );
    setServiceChanged(true);
  };

  const handleLocationPriceChange = (e, serviceId, locationId) => {
    const { value } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId
          ? {
              ...service,
              locations: service.locations.map((loc) =>
                loc.id === locationId ? { ...loc, price: value } : loc
              )
            }
          : service
      )
    );
    setServiceChanged(true);
  };

  const handleAddService = () => {
    const newService = {
      id: doc(collection(database, 'services')).id,
      title: '',
      time: '',
      active: true,
      preEventBuffer: '',
      postEventBuffer: '',
      locations: []
    };
    setServices((prevServices) => [...prevServices, newService]);
    setServiceChanged(true);
  };

  const handleDeleteService = (serviceId) => {
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === serviceId ? { ...service, active: false } : service
      )
    );
    setServiceChanged(true);
  };

  const storeToDb = async (services) => {
    await Promise.all(
      services.map((service) =>
        setDoc(doc(database, 'services', service.id), {
          ...service,
          practitioner: practitioner.uid,
          updateTime: Date.now(),
          locations: service.locations?.map((location) => ({
            id: location.id,
            price: location.price
          }))
        })
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const invalidService = services.find((service) => service.locations.length === 0);

    if (invalidService) {
      toast.error(`Please select at least one location for the service: ${invalidService.title}`);
      return;
    }
    setServiceChanged(false);
    toast.promise(storeToDb(services), {
      success: 'Services saved!',
      loading: 'Saving ...',
      error: 'Failed to save!'
    });
  };

  return (
    <div
      className="margin-top--xs"
      // style={{ maxWidth: "500px" }}
    >
      {isLoading ? <Loader /> : null}

      <form onSubmit={handleSubmit}>
        {services?.map(
          (service, i) =>
            service?.active === true && (
              <div key={i}>
                <div
                  className="flex-container justify-between "
                  style={{
                    // maxWidth: "800px",
                    borderBottom: '1px solid var(--border-gray)'
                  }}>
                  <h4 className="margin-top--xxs">Service {i + 1}</h4>
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => handleDeleteService(service.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
                {/* <p
              style={{

                borderBottom: "1px solid var(--border-gray)"
              }}
            ></p> */}
                <div
                  className="flex-container direction-column"
                  key={service.id}
                  style={{ marginBottom: '20px', maxWidth: '500px' }}>
                  <TextField
                    size="small"
                    name="title"
                    label="Service type"
                    value={service.title}
                    onChange={(e) => handleServiceChange(e, service.id)}
                    required
                    sx={{ marginBottom: '1rem', marginTop: '1rem' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PsychologyOutlinedIcon
                            sx={{ transform: 'scaleX(-1)', color: 'black' }}
                          />
                        </InputAdornment>
                      )
                    }}
                    placeholder="Enter service name"
                  />

                  <TextField
                    size="small"
                    name="time"
                    label="Duration (min)"
                    type="number"
                    value={service.time}
                    onChange={(e) => handleServiceChange(e, service.id)}
                    required
                    sx={{ maxWidth: '200px', marginBottom: '1rem' }}
                    inputProps={{ min: 0 }}
                  />

                  <h5 className="margin-top--sm" style={{ color: 'black' }}>
                    Buffer Time
                  </h5>
                  <TextField
                    size="small"
                    name="preEventBuffer"
                    label="Before Event (min)"
                    type="number"
                    value={service.preEventBuffer}
                    onChange={(e) => handleServiceChange(e, service.id)}
                    // required
                    sx={{ maxWidth: '200px', marginBottom: '1rem' }}
                    inputProps={{ min: 0 }}
                  />

                  <TextField
                    size="small"
                    name="postEventBuffer"
                    label="After Event (min)"
                    type="number"
                    value={service.postEventBuffer}
                    onChange={(e) => handleServiceChange(e, service.id)}
                    // required
                    sx={{ maxWidth: '200px', marginBottom: '1rem' }}
                    inputProps={{ min: 0 }}
                  />

                  <div style={{ marginBottom: '1rem' }}>
                    {locations.map((location) => (
                      <div
                        key={location.id}
                        style={{ marginTop: '0.6rem' }}
                        className="flex-container justify-between">
                        <FormControlLabel
                          style={{
                            maxWidth: `${window.innerWidth < 600 ? '200px' : '350px'}`,
                            alignItems: 'flex-start'
                          }}
                          control={
                            <Checkbox
                              checked={service.locations.some((loc) => loc.id === location.id)}
                              onChange={(e) => handleLocationChange(e, service.id, location)}
                              style={{ paddingTop: 0 }}
                            />
                          }
                          label={location.name}
                        />
                        {service.locations.some((loc) => loc.id === location.id) && (
                          <TextField
                            required
                            size="small"
                            label="Rate"
                            type="number"
                            value={
                              service.locations.find((loc) => loc.id === location.id)?.price || ''
                            }
                            onChange={(e) => handleLocationPriceChange(e, service.id, location.id)}
                            sx={{ width: '100px' }}
                            placeholder="₹ 0"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
        )}

        <Button style={{ paddingLeft: '0px' }} color="primary" onClick={handleAddService}>
          Add new service
        </Button>

        <div className="floating-button">
          <Fab
            style={{ width: '120px' }}
            variant="extended"
            color="primary"
            type="submit"
            disabled={!serviceChanged}>
            Save
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default ServiceList;
