import {
  collection,
  query,
  where,
  getDocs,
  startAfter,
  limit,
  orderBy,
  doc,
  getDoc
} from 'firebase/firestore';
import { auth, database } from '../../../helper/firebase.helper';

const PAGE_SIZE = 20;

export const fetchHistory = async (lastVisible, orgId, clientId) => {
  const historyRef = collection(database, 'activities');
  let q = query(
    historyRef,
    where('organisation', 'in', orgId),
    where('client', '==', clientId),
    orderBy('update_time', 'desc'),
    limit(PAGE_SIZE)
  );

  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }

  const querySnapshot = await getDocs(q);

  let dataArray = [];
  querySnapshot.forEach((doc) => {
    dataArray.push({ id: doc.id, ...doc.data() });
  });

  if (dataArray.length < PAGE_SIZE) {
    // If the fetched data is less than the page size, it means there are no more pages
    return {
      data: dataArray,
      hasMore: false,
      lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
  }

  return {
    data: dataArray,
    hasMore: true,
    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1]
  };
};

export const getDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);

  const formattedDateYear = `${formattedDate} `;

  return formattedDateYear;
};

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return '#4dd74d';
    case 0:
      return '#f4db05';
    case 2:
      return 'red';
    case 3:
      return 'red';
    default:
      return '#0884ff';
  }
};

export const getTime = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    day: 'numeric',
    month: 'short'
  };
  const optionsYear = {
    year: 'numeric'
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);
  const formattedYear = date.toLocaleString(undefined, optionsYear);
  const formattedTime = date.toLocaleString(undefined, optionsTime);

  const formattedDateTime = `${formattedDate}, ${formattedYear} at ${formattedTime}`;

  return formattedDateTime;
};

export const fetchFieldsFromDb = async (orgId) => {
  try {
    const docRef = doc(database, `organisations/${orgId}/details/custom_fields`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data().fields;
    } else {
      console.log('No such document!');
      return [];
    }
  } catch (error) {
    console.error('Error fetching document: ', error.message);
    return [];
  }
};

export const fetchClientDetailsFromDb = async (clientId) => {
  try {
    const docRef = doc(database, `clients/${clientId}/details/custom_fields`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return {};
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    return {};
  }
};
