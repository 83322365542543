import { NavLink, useMatch } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PeopleIcon from '@mui/icons-material/People';
import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoContainer from '../../../../shared/components/logocontainers/logocontainer';
import styles from './Sidebar.module.scss';
import clsx from 'clsx';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Accordion from '../../../../shared/components/accordion/Accordion';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WorkIcon from '@mui/icons-material/Work';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import MessageIcon from '@mui/icons-material/Message';

export default function Sidebar({ sidebar, handleSidebar }) {
  const drawerWidth = 240;
  const DrawerHeader = styled(Drawer)({
    width: drawerWidth,
    flexShrink: 0,
    overflow: 'auto',
    '.MuiDrawer-paper': {
      width: drawerWidth,
      borderRight: 'none',
      top: 'auto'
    }
  });
  const practice = useMatch('/dashboard/practice/*');
  // const meeting = useMatch("/dashboard/meeting/*");

  // const style = useMatch("/dashboard/style/*");

  return (
    <DrawerHeader
      container={document.getElementById('dashboard-container')}
      variant={window.innerWidth > 960 ? 'permanent' : 'temporary'}
      anchor="left"
      open={sidebar}
      onClose={handleSidebar}
      hideBackdrop
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}>
      <div className={styles.sidebar}>
        <div className={styles.top}>
          <LogoContainer handleSidebar={handleSidebar} />
        </div>
        <div className={styles.sidebar_list}>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/bookings"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <MeetingRoomIcon />
            <div>Bookings</div>
          </NavLink>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/clients"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <PeopleIcon />
            <div>Clients</div>
          </NavLink>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/profile"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <AccountCircleIcon />
            <div>Profile</div>
          </NavLink>

          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/availability"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <EventAvailableIcon />
            <div>Availability</div>
          </NavLink>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/services"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <PsychologyIcon />
            <div>Services</div>
          </NavLink>
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/messages"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <MessageIcon />
            <div>Messages</div>
          </NavLink>

          {window.innerWidth > 960 && sidebar ? (
            <div className={styles.sidebar_accordion}>
              <Accordion
                tab={1}
                Icon={WorkIcon}
                Arrow={KeyboardArrowRightIcon}
                opened={practice}
                rotation={90}>
                <div className={styles.accordion_title}>My Practice</div>
                <div className={styles.accordion_body}>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/practice/branding"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Branding
                  </NavLink>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/practice/domain"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Domain
                  </NavLink>
                  <NavLink
                    role="link"
                    className={({ isActive }) => clsx(styles.link, isActive && styles.active_link)}
                    to="/dashboard/practice/invites"
                    onClick={window.innerWidth < 960 ? handleSidebar : null}>
                    Invites
                  </NavLink>
                </div>
              </Accordion>
            </div>
          ) : (
            <NavLink
              role="link"
              className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
              to="/dashboard/practice"
              onClick={window.innerWidth < 960 ? handleSidebar : null}>
              <WorkIcon />
              <div>My Practice</div>
            </NavLink>
          )}
          <NavLink
            role="link"
            className={({ isActive }) => clsx(styles.single_link, isActive && styles.active_link)}
            to="/dashboard/other-practices"
            onClick={window.innerWidth < 960 ? handleSidebar : null}>
            <AddBusinessIcon />
            <div>Other Practices</div>
          </NavLink>
          {/* // )} */}
        </div>
      </div>
    </DrawerHeader>
  );
}
