import React from 'react';
import BookingItem from './booking-item/BookingItem';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import BookingTimeline from './booking-timeline/BookingTimeline';

const AccordionItem = ({ schedule, day }) => {
  return (
    <>
      <div className="accordion-container">
        <Accordion
          defaultExpanded={
            new Date(schedule[day]?.[0]?.meetingStart).getDate() === new Date().getDate()
          }
          style={{ marginTop: '30px', marginBottom: '10px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{
              padding: window.innerWidth > 550 ? '0px 5px 0px 20px' : '0px 3px 0px 10px'
            }}>
            <div
              style={{
                width: '100%'
              }}>
              <div
                className="flex-container"
                style={{
                  display: 'flex',
                  width: '100%'
                }}>
                <div className="date-icon flex-container align-center justify-center">
                  <p style={{ marginTop: '-1px', textAlign: 'center' }}>
                    {/* {getDate(schedule[day]?.[0].meetingStart)} */}
                    {format(new Date(schedule[day]?.[0].meetingStart), 'd')}
                  </p>
                </div>
                <div style={{ marginLeft: '20px', textAlign: 'right' }}>
                  <p>
                    {/* {getMonthYear(schedule[day]?.[0]?.meetingStart)} */}
                    {format(new Date(schedule[day]?.[0]?.meetingStart), 'MMM yyyy')}
                  </p>
                  <p>
                    {/* {getDay(schedule[day]?.[0]?.meetingStart)} */}
                    {format(new Date(schedule[day]?.[0]?.meetingStart), 'EEE')}
                  </p>
                </div>
                <div
                  style={{
                    borderLeft: '1px solid var(--gray-lightest)',
                    marginLeft: '10px',
                    paddingLeft: '10px',
                    flexGrow: 1
                  }}>
                  <div className="flex-container bookings-count-container">
                    <div>{schedule[day]?.length} Bookings &nbsp;</div>
                    <div>
                      ({window.innerWidth > 550 && ' Starts at '}
                      {format(new Date(schedule[day]?.[0]?.meetingStart), 'hh:mm a')}
                      {window.innerWidth < 550 && ' - '}
                      {window.innerWidth > 550 && ' ends at '}
                      {format(
                        new Date(schedule[day]?.[schedule[day].length - 1]?.meetingEnd),
                        'hh:mm a'
                      )}{' '}
                      )
                    </div>
                  </div>
                  {window.innerWidth > 550 && <BookingTimeline schedule={schedule} day={day} />}
                </div>
              </div>
              <div className={window.innerWidth < 551 ? 'margin-bottom--xxxs' : ''}>
                {window.innerWidth < 551 && <BookingTimeline schedule={schedule} day={day} />}
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails style={{ padding: '5px 0px' }}>
            {schedule[day]?.map((booking, i) => (
              <BookingItem key={i} booking={booking} />
            ))}
          </AccordionDetails>
        </Accordion>
      </div>
      <style jsx>{`
        .accordion-container {
          margin: auto;
          width: 98%;
        }
        .date-icon {
          width: 38px;
          height: 38px;
          background-color: #007aff;
          color: white;
          border-radius: 50%;
          font-size: 20px;
        }
        @media only screen and (max-width: 550px) {
          .bookings-count-container {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  );
};

export default AccordionItem;
