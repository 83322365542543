import React, { useState, createRef, useEffect } from 'react';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import { Fab, IconButton, TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';
import { getBrandingDataFromDb, storeBrandingToDb } from './branding.service';

const Branding = ({ freePlanPractitioner }) => {
  const [brandingData, setBrandingData] = useState({});
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();
  const [detailsUpdated, setDetailsUpdated] = useState(false);
  const practitionerData = useStoreState((state) => state.practitionerData);

  const inputLogoRef = createRef();
  const inputBannerRef = createRef();

  const handleBrandingUpdate = (field, val) => {
    setBrandingData({
      ...brandingData,
      [field]: val
    });
    setDetailsUpdated(true);
  };

  const handleFileChange = (event, type) => {
    if (event.target.files[0].size / 1024 / 1024 < 5) {
      if (type === 'logo') {
        handleBrandingUpdate('newLogo', event.target.files[0]);
        if (event.target.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            setLogo(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      } else {
        handleBrandingUpdate('newBanner', event.target.files[0]);
        if (event.target.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
            setBanner(e.target.result);
          };
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    } else {
      event.target.files = '';
      toast.error('File size cannot be more than 5mb');
    }
  };

  const resetBrandLogo = () => {
    handleBrandingUpdate('newLogo', null);
    handleBrandingUpdate('logo', null);
    setLogo();
    inputLogoRef.current.value = '';
  };

  const resetBanner = () => {
    handleBrandingUpdate('newBanner', null);
    handleBrandingUpdate('banner', null);
    setBanner();
    inputBannerRef.current.value = '';
  };

  const handleSave = async () => {
    if (!brandingData.logo && !brandingData.newLogo) {
      toast.error('Please upload a brand logo');
      return;
    }
    if (!brandingData.banner && !brandingData.newBanner) {
      toast.error('Please upload a banner');
      return;
    }
    if (!brandingData.name) {
      toast.error('Please fill the business name');
      return;
    }
    try {
      await toast.promise(storeBrandingToDb(practitionerData.own_organisation, brandingData), {
        loading: 'Saving...',
        success: 'Saved!',
        error: `Couldn't save`
      });
      const updatedData = await getBrandingDataFromDb(practitionerData.own_organisation);
      console.log(updatedData);
      setBrandingData(updatedData);
      setDetailsUpdated(false);
    } catch (error) {
      console.log(error.message);
      toast.error('Error saving data:', error.message);
    }
  };

  const getBrandingData = async () => {
    getBrandingDataFromDb(practitionerData.own_organisation).then((res) => {
      console.log(res);
      setBrandingData(res);
    });
  };

  useEffect(() => {
    getBrandingData();
  }, [practitionerData]);

  return (
    <div>
      <Helmet>
        <title>Branding - DeTalks</title>
      </Helmet>
      <h4 className="heading margin-top--sm">
        Branding <sup className="beta">BETA</sup>
      </h4>
      <div className="margin-top--sm margin-bottom--sm">
        <fieldset className="logo-container flex-container">
          <legend>Brand logo</legend>
          {logo || brandingData?.logo ? (
            <img className="logo" src={logo || brandingData?.logo} alt="Brand logo" />
          ) : null}
          <div className="action-buttons flex-container direction-column">
            <div className="action-button delete-button">
              {brandingData?.logo || logo ? (
                <IconButton
                  onClick={resetBrandLogo}
                  color="primary"
                  disabled={freePlanPractitioner}
                  aria-label="delete"
                  component="span">
                  <DeleteIcon style={{ color: 'var(--gray-light)' }} />
                </IconButton>
              ) : null}
            </div>
            <div className="action-button">
              <input
                ref={inputLogoRef}
                onChange={(e) => handleFileChange(e, 'logo')}
                style={{
                  display: 'none'
                }}
                accept="image/png, image/jpeg, image/jpg"
                id="icon-button-file"
                type="file"
              />
              <IconButton
                onClick={() => inputLogoRef.current.click()}
                color="primary"
                disabled={freePlanPractitioner}
                aria-label="upload"
                component="span">
                <PhotoCameraIcon />
              </IconButton>
            </div>
          </div>
        </fieldset>
        <p className="helper-text margin-top--xxs">
          Your brand logo is used for co-branding at various places like PDF reports etc.
        </p>
      </div>
      <fieldset className="logo-container flex-container">
        <legend>Banner</legend>
        {banner || brandingData?.banner ? (
          <img className="logo" src={banner || brandingData?.banner} alt="Banner" />
        ) : null}
        <div className="action-buttons flex-container direction-column">
          <div className="action-button delete-button">
            {brandingData?.banner || banner ? (
              <IconButton
                onClick={resetBanner}
                color="primary"
                disabled={freePlanPractitioner}
                aria-label="delete"
                component="span">
                <DeleteIcon style={{ color: 'var(--gray-light)' }} />
              </IconButton>
            ) : null}
          </div>
          <div className="action-button">
            <input
              ref={inputBannerRef}
              onChange={(e) => handleFileChange(e, 'banner')}
              style={{
                display: 'none'
              }}
              accept="image/png, image/jpeg, image/jpg"
              id="icon-button-file"
              type="file"
            />
            <IconButton
              onClick={() => inputBannerRef.current.click()}
              color="primary"
              disabled={freePlanPractitioner}
              aria-label="upload"
              component="span">
              <PhotoCameraIcon />
            </IconButton>
          </div>
        </div>
      </fieldset>
      <div className="margin-top--md margin-bottom--sm">
        <TextField
          required
          variant="outlined"
          name="business name"
          label="Business name"
          disabled={freePlanPractitioner}
          size="small"
          onChange={(e) => handleBrandingUpdate('name', e.target.value)}
          value={brandingData?.name || ''}
          helperText="Your business name is used for co-branding at various places like PDF reports etc."
        />
      </div>
      <div className="floating-button">
        <Fab
          style={{ width: '120px' }}
          variant="extended"
          color="primary"
          onClick={handleSave}
          disabled={!detailsUpdated}>
          Save
        </Fab>
      </div>
      <style jsx>{`
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
        .beta {
          font-size: var(--text-xxxs);
          font-weight: 400;
        }
        .logo-container {
          border: 1px solid rgba(0, 0, 0, 0.3);
          color: rgba(0, 0, 0, 0.54);
          max-width: 300px;
          height: 150px;
          justify-content: flex-end;
          position: relative;
        }
        .helper-text {
          color: rgba(0, 0, 0, 0.54);
          margin-left: var(--space-md);
          font-size: 0.75em;
        }
        .logo {
          position: absolute;
          width: calc(100% - 1.4em);
          height: calc(100% - 1.4em);
          object-fit: contain;
        }
        .action-buttons {
          justify-content: space-between;
        }
        .action-button {
          margin-bottom: -10px;
          margin-right: -10px;
        }
        .delete-button {
          margin-top: -10px;
        }
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default Branding;
