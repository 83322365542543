import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { fetchClientHistory, getDate, getStatusColor } from './clients.service';
import { useStoreState } from 'easy-peasy';
import { format } from 'date-fns';

const ListItem = ({ client }) => {
  const [clientData, setClientData] = useState(null);

  const user = useStoreState((state) => state.user);

  useEffect(() => {
    fetchClientData();
  }, [client]);

  const fetchClientData = async () => {
    const doc = await fetchClientHistory(user.orgId, client.id);
    setClientData(doc.data);
  };

  return (
    <div className="flex-container text--sm  outer-container">
      <div className="email-div inner-div flex-container">
        <div className="icon flex-container justify-center align-center">
          {client.email?.charAt(0).toUpperCase()}
        </div>
        <div style={{ marginLeft: '10px', wordBreak: 'break-word' }}>
          <p className="semi-bold"> {client.name}</p>
          <p className="semi-bold">{client.email}</p>
          <p className="semi-bold">{client.mobile}</p>
        </div>
      </div>
      <div className="contact-div inner-div ">
        {clientData && clientData !== (undefined || null) && (
          <>
            <p className="semi-bold">
              {clientData?.type === 0
                ? 'Self-Assessment'
                : clientData.type === 1
                  ? 'Short Course'
                  : clientData.type === 2
                    ? 'Booking'
                    : null}
            </p>
            {clientData.type === 2 ? <p>{clientData.serviceName}</p> : null}
          </>
        )}
        {!clientData && clientData === (undefined || null) && <p>No activity yet!</p>}
      </div>

      <div className="name-div inner-div flex-container ">
        <div className="name">
          {clientData && clientData !== (undefined || null) && (
            <>
              <span className="semi-bold" style={{ color: getStatusColor(clientData.status) }}>
                {clientData.type !== 2 &&
                  (clientData.status === 0
                    ? 'Initiated'
                    : clientData.status === 1
                      ? 'Completed'
                      : clientData.status === 2
                        ? 'Expired'
                        : null)}
                {clientData.type === 2 &&
                  (clientData.status === 0
                    ? 'Scheduled'
                    : clientData.status === 1
                      ? 'Completed'
                      : clientData.status === 2
                        ? 'Expired'
                        : clientData.status === 3
                          ? 'Cancelled'
                          : null)}
              </span>
              &nbsp;&nbsp;
              <span className="semi-bold">{getDate(clientData.update_time)}</span>
              <div className="semi-bold">
                {clientData.type === 2 ? (
                  <>
                    <p>
                      {getDate(clientData.meetingStart)} at{' '}
                      {format(new Date(clientData.meetingStart), 'hh:mm a')} -{' '}
                      {format(new Date(clientData.meetingEnd), 'hh:mm a')}
                    </p>
                    <p>{clientData.locationName}</p>
                  </>
                ) : null}
                {clientData.key_metrics && clientData.score ? (
                  <div>
                    Score : {clientData.score}
                    {clientData.key_metrics.map((item, index) => {
                      return item.key ? (
                        <p key={index}>
                          {item.key}: {item.value}
                        </p>
                      ) : null;
                    })}
                  </div>
                ) : clientData.key_metrics ? (
                  clientData.key_metrics.map((item, index) => {
                    return item.key ? (
                      <p key={index}>
                        {item.key}: {item.value}
                      </p>
                    ) : null;
                  })
                ) : null}
                {!clientData.key_metrics && (clientData.score || clientData.score === 0)
                  ? `Score : ${clientData.score}`
                  : null}
              </div>
            </>
          )}
        </div>
        <div className="flex-container align-center">
          <IconButton style={{ maxHeight: '40px' }}>
            <ArrowForward color="primary" />
          </IconButton>
        </div>
      </div>
      <style jsx>
        {`
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding: 10px 0px 10px 0px;
            justify-content: space-between;
          }
            .outer-container:hover{
            background-color: #eee;}

          .email-div {
            width: 30%;
            overflow: hidden;
          }

          .contact-div {
            width: 20%;
          }
          .name-div {
            width: 40%;
            justify-content:space-between;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .icon {
            background-color: var(--primary-color);
            color: white;
            fontSize: '24px'
            padding: 10px;
            width:30px;
            height:30px;
            border-radius: 50%;
          }
            .semi-bold {
            font-weight: 500;
          }

          @media only screen and ( max-width : 900px ) {

            .email-div {
              width: 80% !important;
            }
            .contact-div {
              display: none;
            }
              .name{
               display: none;
              }
            .name-div {
              width: 20% !important;
               justify-content:right;
            }
          }

          @media only screen and (max-width: 400px) {
            // .icon-div {
            //   display: none;
            // }
// .icon{
//  display: none;
// }
          }
        `}
      </style>
    </div>
  );
};

export default ListItem;
