import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  SwipeableDrawer
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import { getTime } from '../clientHistory.service';
import { format } from 'date-fns';

const CancelPopup = ({
  open,
  handleClose,
  handleSave,
  text,
  setText,
  handleOpen,
  cancelled,
  bookingItem
}) => {
  return (
    <>
      {window.innerWidth > 750 && (
        <>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: 4,
                padding: 2,
                minHeight: '150px'
              }
            }}>
            <DialogTitle>
              <div>
                <h4 className="margin-top--xxxs"> Cancelled by practitioner</h4>
                <p style={{ marginTop: '-5px' }}>
                  <small
                    className="gray-text"
                    style={{ fontSize: 'var(--text-xs)', marginTop: '-5px' }}>
                    {bookingItem?.cancellationTime
                      ? getTime(bookingItem.cancellationTime)
                      : getTime(Date.now())}
                  </small>
                </p>
              </div>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  right: 40,
                  top: 40,
                  border: '1px solid #0884ff',
                  borderRadius: '50%',
                  padding: '1px'
                }}>
                <CloseIcon color="primary" fontSize="18px" />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {cancelled ? (
                <p>{text ? text : null}</p>
              ) : (
                <TextField
                  multiline
                  margin="dense"
                  rows={8}
                  variant="outlined"
                  fullWidth
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter a reason for cancellation (e.g., client no-show, client request, emergency) for fututre reference. Please enter carefully, as this cannot be changed later."
                />
              )}
            </DialogContent>
            <DialogActions sx={{ padding: '0px 25px' }}>
              {!cancelled && (
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<LockIcon />}
                  onClick={handleSave}>
                  {text ? 'Save & Cancel' : 'Cancel'}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </>
      )}
      {window.innerWidth < 751 && (
        <SwipeableDrawer
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          anchor="bottom"
          PaperProps={{
            sx: {
              top: '40%',
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
              padding: '10px'
            }
          }}>
          <div>
            <div className="flex-container justify-between">
              <h4 className="margin-top--xxxs"> Cancelled by practitioner</h4>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  right: 11,
                  top: 18,
                  border: '1px solid #0884ff',
                  borderRadius: '50%',
                  padding: '1px'
                }}>
                <CloseIcon color="primary" fontSize="18px" />
              </IconButton>
            </div>
            <p style={{ marginTop: '-5px' }}>
              <small
                className="gray-text"
                style={{ fontSize: 'var(--text-xs)', marginTop: '-5px' }}>
                {bookingItem?.cancellationTime
                  ? getTime(bookingItem.cancellationTime)
                  : getTime(Date.now())}
              </small>
            </p>
          </div>
          <div>
            {cancelled ? (
              <p className="margin-top--md">{text ? text : null}</p>
            ) : (
              <TextField
                multiline
                margin="dense"
                rows={8}
                variant="outlined"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter a reason for cancellation (e.g., client no-show, client request, emergency) for fututre reference. Please enter carefully, as this cannot be changed later."
              />
            )}
          </div>
          <div>
            {!cancelled && (
              <Button
                size="small"
                variant="contained"
                startIcon={<LockIcon />}
                onClick={handleSave}
                style={{
                  position: 'absolute',
                  right: '11px',
                  marginTop: '10px'
                }}>
                {text ? 'Save & Cancel' : 'Cancel'}
              </Button>
            )}
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default CancelPopup;
